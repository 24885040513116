import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../pages/404";
import LoginPage from "../pages/Login";
import SignUpPage from "../pages/SignUp";
import ContactUs from "../pages/ContactUs";
import PrivacyPolicy from "../pages/Privacy";
import TermsandCond from "../pages/Terms";
import Research from "../pages/LawPotato";


interface Page {
  component: React.FC;
  path: string;
}

function useWindowSize() {
  
  
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    
    function handleResize() {
      
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    window.addEventListener("resize", handleResize);
    
    handleResize();
    
    return () => window.removeEventListener("resize", handleResize);
  }, []); 
  return windowSize;
}


const pages: Page[] = [
  { component: Home, path: '/' },
    { component: NotFound, path: '*' }, 
    { component: LoginPage, path: '/login' },
    { component: SignUpPage, path: '/signup' },
    { component: ContactUs, path: '/contactUs' },
    { component: PrivacyPolicy, path: '/privacy' },
    { component: TermsandCond, path: '/terms' },
    {component: Research, path: '/lawPotato'}
];



const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
     
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}

      </Routes>

    </BrowserRouter>
  );
};

export default MyRoutes;
