import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
const NotFound: React.FC = () => {

    const cursorRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
   
    document.addEventListener('mousemove', (e) => {
      gsap.to(cursorRef.current, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.3,
        ease: 'power2.out',
      });
    });
    }, []);
    
    return (
     <div className="flex flex-col items-center justify-center h-screen bg-bakedPotato text-center">
    <div ref={cursorRef} className="fixed w-6 h-6 rounded-full bg-bakedPotato mix-blend-difference z-50"></div>
    <h1 className="text-9xl font-bold text-creamyYellow">🥔 404</h1>
    <h2 className="text-4xl font-semibold mt-4 text-forrestGreen">
        Oops! This Recipe is Missing!
    </h2>
    <div className="mt-6">
        <img src="angryPotato.png" alt="Angry Potato" className="w-40 h-40 object-contain mx-auto" />
    </div>
    <p className="mt-4 text-black text-2xl">
        Looks like the potato you were searching for got lost in the kitchen! <br /> 
        Don’t worry, our chefs are on the case.
    </p>
    <Link 
        to="/" 
        className="mt-6 px-6 py-3 bg-lightSkin text-black text-3xl font-bold shadow-xl rounded-full hover:bg-red-200 hover:scale-105 transform transition duration-300"
    >
        Return to the Kitchen
    </Link>
</div>

    );
};

export default NotFound;
