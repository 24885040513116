import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Alert from '../components/Alert'; 
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { apiUri } from '../constants';

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState("Login attempt unsuccessful. Please check your credentials and try again.")
    const [showAlert, setShowAlert] = useState(false); 
    const headingRef = useRef<HTMLHeadingElement | null>(null);
    const formRef = useRef<HTMLFormElement | null>(null);
    const cursorRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate(); 

    const handleLoginClick = async (e: React.MouseEvent) => {
        e.preventDefault(); 

        
        if (!email || !password) {
            setShowAlert(true); 
            return;
        }

        try {
            
            const response = await axios.post(`${apiUri}/lawyerLogin`, {
                email: email,
                password: password,
            });

            
            if (response.data.success == true) {
                localStorage.setItem("email", email)
                navigate('/lawPotato'); 
            } else {
                setAlertMessage(response.data.data)
                setShowAlert(true)
            }

        } catch (error) {
            setShowAlert(true); 
        }
    };

    
    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    useEffect(() => {
        document.addEventListener('mousemove', (e) => {
            gsap.to(cursorRef.current, {
                x: e.clientX,
                y: e.clientY,
                duration: 0.3,
                ease: 'power2.out',
            });
        });
        const tl = gsap.timeline();

        tl.from(headingRef.current, {
            opacity: 0,
            y: -30,
            duration: 1,
            ease: 'power2.out',
        })
        .from(formRef.current, {
            opacity: 0,
            scale: 0.9,
            duration: 1,
            ease: 'power2.out',
        }, '-=0.5'); 
    }, []);

    return (
        <div className="min-h-screen bg-bakedPotato flex flex-col items-center justify-center">
            <div ref={cursorRef} className="fixed w-6 h-6 rounded-full bg-bakedPotato mix-blend-difference z-50"></div>
            <div className='w-full'>
                <Header />
            </div>

            <div className="w-full max-w-md mx-auto bg-tan p-8 rounded-xl shadow-lg mt-8 mb-8">
                <h2 ref={headingRef} className="text-4xl font-bold text-center text-bakedPotato mb-6">
                    🥔 Welcome Back to LawPotato!
                </h2>
                <p className="text-lg text-center text-forrestGreen mb-10">
                    Login to continue cooking up legal research magic! 🔍🥔
                </p>

                <form ref={formRef} className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-lg font-semibold text-bakedPotato mb-2">
                            Email (Your Kitchen ID) ✉️
                        </label>
                        <input
                            required
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            className="w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-sageGreen"
                        />
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-lg font-semibold text-bakedPotato mb-2">
                            Secret Recipe (Password) 🔑
                        </label>
                        <input
                            required
                            type="password"
                            id="password"
                            placeholder="Enter your secret recipe"
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            className="w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-sageGreen"
                        />
                    </div>

                    <div className="flex justify-end">
                        <a href="#" className="text-forrestGreen font-bold hover:text-sageGreen">
                            Forgot your recipe?
                        </a>
                    </div>

                    <div className="flex justify-center">
                        <button
                            onClick={handleLoginClick}
                            className="bg-bakedPotato text-white text-xl font-bold py-3 px-8 rounded-full hover:bg-mutedTeal transition-all transform hover:scale-110"
                        >
                            🍴 Let's Dig In!
                        </button>
                    </div>
                </form>

                <p className="text-center text-gray-600 mt-6">
                    Don't have an account?{' '}
                    <a href="/signup" className="text-forrestGreen font-bold hover:text-sageGreen">
                        Sign up here!
                    </a>
                </p>
            </div>

            <div className='w-full'>
                <Footer />
            </div>

            
            {showAlert && (
                <Alert 
                    message={alertMessage} 
                    onClose={handleCloseAlert} 
                />
            )}
        </div>
    );
};

export default LoginPage;
