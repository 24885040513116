import React, { useEffect, useRef } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import gsap from 'gsap';

const PrivacyPolicy: React.FC = () => {
    const cursorRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
   
    document.addEventListener('mousemove', (e) => {
      gsap.to(cursorRef.current, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.3,
        ease: 'power2.out',
      });
    });
    }, []);
    
    return (
        <div>
             <div ref={cursorRef} className="fixed w-6 h-6 rounded-full bg-bakedPotato mix-blend-difference  z-50"></div>
            <Header />
    <div className=" mx-auto p-6 bg-bakedPotato ">
      <h1 className="text-4xl font-bold mb-4 flex justify-center">Privacy Policy</h1>
      <p className="mb-4">
        At <strong>TAK Law Labs AI Pvt Ltd</strong> (hereinafter "the Company/we/us"), we care about your personal data and how it is used.
      </p>
      <p className="mb-4">
        With this objective, we have devised this Privacy Policy so that you can confidently browse <a href="https://www.lawpotato.com" className="text-forrestGreen hover:underline">https://www.lawpotato.com</a> (the "Website").
        By visiting this website, you agree to and accept the terms of this Privacy Policy.
      </p>

      <h2 className="text-xl font-semibold mb-2">Important information and who we are</h2>
      <p className="mb-4">
        The Company respects your right to privacy and is committed to fostering a trustworthy environment when processing your personal data, in line with applicable statutory requirements and this Policy.
        This Privacy Policy explains how we handle and process your personal data that you entrust to us when using this Website.
      </p>
      <p className="mb-4">
        This Policy allows you to make an informed decision before communicating your personal data to us.
      </p>
      <p className="mb-4">
        For the purpose of applicable data protection laws, the Company is considered the Data Controller and will ensure the proper degree of care when processing your personal data.
      </p>
      <p className="mb-4">
        When browsing our Website, we do not collect any personal data. You can browse the Website without providing any personal information.
      </p>

      <h2 className="text-xl font-semibold mb-2">How do we use your data?</h2>
      <p className="mb-4">
        Since we do not store any personal data, we do not use your personal data for any purpose. Any data provided during your session is not retained once the session ends.
      </p>
      
      <h2 className="text-xl font-semibold mb-2">Cookies Policy</h2>
      <p className="mb-4">
        Our Website uses cookies to gather information about your computer for our services and to provide statistical information regarding the use of our Website. This information is statistical and does not identify any personal details. 
      </p>
      <p className="mb-4">
        You can decline cookies through your browser settings. However, declining cookies may limit your ability to access parts of our Website.
      </p>

      <h2 className="text-xl font-semibold mb-2">Protecting the data</h2>
      <p className="mb-4">
        We have implemented security policies, rules, and technical measures to protect your data against:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Unauthorized access;</li>
        <li>Improper use or disclosure;</li>
        <li>Unauthorized modification;</li>
        <li>Unlawful destruction or accidental loss.</li>
      </ul>
      <p className="mb-4">
        Since we do not store personal data, we do not retain any personal data beyond the session.
      </p>

      <h2 className="text-xl font-semibold mb-2">Your rights</h2>
      <p className="mb-4">
        You have the right to withdraw consent or stop the processing of your data. However, since we do not store any personal data, there is no data to rectify or erase.
      </p>
      <p className="mb-4">
        Complaints about breaches of this Policy or data protection laws may be reported to our Data Protection Officer.
      </p>

      <h2 className="text-xl font-semibold mb-2">No Third-Party Data Sharing</h2>
      <p className="mb-4">
        The Company does not sell or share your personal data with third parties. Your data is deleted after the session ends. Although we take strong precautions, we are not responsible for any potential data leaks.
      </p>
      <p className="mb-4">
        Please be aware that the AI model used may process the data you provide for training and improvement purposes, but it does not store any personal data.
      </p>

      <h2 className="text-xl font-semibold mb-2">Contact details</h2>
      <p className="mb-4">
        <strong>TAK Law Labs AI Pvt Ltd</strong><br />
        SPD Plaza <br />
        Koramangala Industrial Layout, 5th Block <br />
        Koramangala, Bengaluru, Karnataka 560034<br />
        Email: <a href="mailto:support@lawpotato.com" className="text-forrestGreen hover:underline">support@lawpotato.com</a>
      </p>

      <p className="text-sm mt-4">
        We may update this Privacy Policy from time to time. Any changes will be posted here, and continued use of the site means you accept those changes.
      </p>
            </div>
            <Footer />
            </div>
  );
};

export default PrivacyPolicy;
