import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CheckCircle, XCircle } from 'lucide-react';

gsap.registerPlugin(ScrollTrigger);

const HowAreWeDifferent = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const lawPotatoRef = useRef(null);
  const competitorsRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const title = titleRef.current;
    const lawPotato = lawPotatoRef.current;
    const competitors = competitorsRef.current;
    const image = imageRef.current;

    gsap.fromTo(section, 
      { opacity: 0 }, 
      { opacity: 1, duration: 1, scrollTrigger: { trigger: section, start: 'top bottom', end: 'center center', scrub: true } }
    );

    gsap.fromTo(title, 
      { y: 50, opacity: 0 }, 
      { y: 0, opacity: 1, duration: 1.5, scrollTrigger: { trigger: title, start: 'top bottom-=100', end: 'top center', scrub: true } }
    );

    gsap.fromTo(lawPotato, 
      { x: -50, opacity: 0 }, 
      { x: 0, opacity: 1, duration: 1.5, scrollTrigger: { trigger: lawPotato, start: 'top bottom-=50', end: 'top center', scrub: true } }
    );

    gsap.fromTo(competitors, 
      { x: 50, opacity: 0 }, 
      { x: 0, opacity: 1, duration: 1.5, scrollTrigger: { trigger: competitors, start: 'top bottom-=50', end: 'top center', scrub: true } }
    );

    gsap.fromTo(image, 
      { y: 50, opacity: 0 }, 
      { y: 0, opacity: 1, duration: 1.5, scrollTrigger: { trigger: image, start: 'top bottom-=50', end: 'top center', scrub: true } }
    );
  }, []);

  return (
    <section ref={sectionRef} className="bg-bakedPotato py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 ref={titleRef} className="text-4xl sm:text-5xl font-bold mb-16 text-center text-creamyYellow">Why Us?</h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 items-start">
          {/* LawPotato Card */}
          <div ref={lawPotatoRef} className="bg-creamyYellow text-black p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-6 text-black">🥔 LawPotato - Your Legal Research Chef 👩‍🍳</h3>
           <ul className="space-y-4 text-lg">
        <li className="flex items-center">
          <span className="text-2xl mr-2">👩‍🍳</span> No input required, our algorithm cooks up the best results for you!
        </li>
        <li className="flex items-center">
          <span className="text-2xl mr-2">💰</span> Super affordable - we won’t drain your wallet!
        </li>
        <li className="flex items-center">
          <span className="text-2xl mr-2">🥄</span> An interactive UI that’s as easy as pie!
        </li>
        <li className="flex items-center">
          <span className="text-2xl mr-2">🧂</span> All your tools in one place - no hidden charges, no sneaky surprises!
        </li>
      </ul>
          </div>

          
          <div ref={imageRef} className="flex justify-center items-center">
            <img src="nerdPotato.png" alt="Nerd Potato" className="rounded-lg w-64 h-auto" />
          </div>

          
          <div ref={competitorsRef} className="bg-creamyYellow text-black p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-6 text-black">🤖 Competitors - The Mashed Potatoes 🍽️</h3>
            <ul className="space-y-4 text-lg">
        <li className="flex items-center">
          <span className="text-2xl mr-2">🔒</span> Privacy concerns? They store your data like leftovers in the fridge!
        </li>
        <li className="flex items-center">
          <span className="text-2xl mr-2">💸</span> Their AI model is not fine-tuned - more like overcooked!
        </li>
        <li className="flex items-center">
          <span className="text-2xl mr-2">💵</span> Expensive pricing that will make you feel like you just bought a gourmet meal!
        </li>
      </ul>
          </div>
        </div>
        
        <div className="mt-16 text-center">
          <p className="text-xl text-gray-700">Plus, we've got an awesome mascot! 🎉🥔</p>
        </div>
      </div>
    </section>
  );
};

export default HowAreWeDifferent;
