import React, { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { X, Maximize2, Minimize2 } from 'lucide-react';

interface ViewFullCasePopoverProps {
  onClose: () => void;
  document: string;
}

interface DocumentRendererProps {
  documentString: string;
}

const DocumentRenderer: React.FC<DocumentRendererProps> = ({ documentString }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: documentString }}
      className="container space-y-10 text-black" 
    />
  );
};

const ViewFullCasePopover: React.FC<ViewFullCasePopoverProps> = ({ onClose, document }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <Popover>
      <Transition
        show={true}
        enter="transition duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition duration-150 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel 
          static 
          className="fixed inset-0 flex items-center justify-center overflow-hidden bg-bakedPotato bg-opacity-75 z-50"
        >
          <div className={`rounded-lg shadow-xl bg-creamyYellow overflow-scroll ${
            isFullScreen ? 'w-full h-full' : 'w-11/12 h-5/6 max-w-6xl max-h-[90vh]'
          }`} >
            <div className="sticky top-0 flex justify-between items-center p-4 bg-bakedPotato text-white z-10">
              <h2 className="text-xl font-bold">Full Case Details</h2>
              <div className="flex space-x-2">
                <button
                  onClick={toggleFullScreen}
                  aria-label={isFullScreen ? "Exit full screen" : "Enter full screen"}
                  className="focus:outline-none"
                >
                  {isFullScreen ? <Minimize2 className="h-5 w-5" /> : <Maximize2 className="h-5 w-5" />}
                </button>
                <button
                  onClick={onClose}
                  aria-label="Close"
                  className="focus:outline-none"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="relative w-full h-[calc(100%-60px)] overflow-y-auto p-4">
              <DocumentRenderer documentString={document} />
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default ViewFullCasePopover;
