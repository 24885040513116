import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import PotatoFloatSvg from './PotatoFloating';

gsap.registerPlugin(ScrollTrigger, TextPlugin, MotionPathPlugin);

const LawPotatoLanding: React.FC = () => {
  const headerRef = useRef<HTMLHeadingElement>(null);
  const mainTextRef = useRef<HTMLHeadingElement>(null);
  const featureRefs = useRef<(HTMLDivElement | null)[]>([]);


  useEffect(() => {
    gsap.to(mainTextRef.current, {
      duration: 2,
      text: "The only ingredient missing from your legal research dish",
      ease: "none",
    });

    featureRefs.current.forEach((feature, index) => {
      gsap.fromTo(
        feature,
        { y: 100, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: feature,
            start: 'top bottom-=100',
            end: 'bottom center',
            scrub: true,
          },
        }
      );
    });

  
  }, []);

  return (
    <div className="bg-bakedPotato text-white overflow-hidden relative">
      <div className="pt-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto relative">

       
          <span className="py-6 px-4 sm:px-6 lg:px-8 w-full flex justify-center items-center">
            <h1
              ref={headerRef}
              className="text-4xl sm:text-6xl font-bold text-black relative"
            >
              LawPotato
            </h1>
          </span>

      
          <h2
            ref={mainTextRef}
            className="text-5xl sm:text-7xl font-extrabold mb-12  text-center"
          ></h2>

          
          <p className="text-3xl mb-24 text-creamyYellow text-center">
            Butter up your legal research process, enhanced with a dash of AI for that extra flavor.
          </p>

     
    
            <PotatoFloatSvg />
          

   
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-12">
    {[
      { title: '💰 Affordable', description: 'Empowers solo practitioners, with minimal charges.' },
      {
        title: '🔒 Privacy Guaranteed',
        description:
          'We prioritize your data security and do not store case files. All information remains private and secure.',
      },
      { title: '📚 Interact with Case Files', description: 'Read full judgments, ask questions to our potato 🥔 from the file. ' },
      { title: '⏱️ Time Saver', description: 'Efficient research process. We process hundereds of cases, saving your crucial time.' },
    ].map((feature, index) => (
      <div
        key={feature.title}
        ref={(el) => (featureRefs.current[index] = el)}
        className="w-full"
      >
        <div
          className="min-h-[175px] flex flex-col justify-between p-8 rounded-lg transition-transform duration-300 hover:scale-110 bg-lightSkin shadow-lg"
        >
          <h3 className="text-4xl font-bold mb-4">{feature.title}</h3>
          <p className="text-xl">{feature.description}</p>
        </div>
      </div>
    ))}
  </div>


          <div className="mt-24 mb-12 text-center">
                      <button className="bg-tan text-black text-2xl font-bold py-4 px-12 rounded-full hover:bg-[#FEFFD2] transition duration-300 transform hover:scale-110"
                      onClick={() => {window.location.href = "/lawPotato"}}
                      >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawPotatoLanding;
