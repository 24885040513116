import React from "react";

interface LoadingIconProps {
  text?: string; 
}

const LoadingIcon: React.FC<LoadingIconProps> = ({ text }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img
        src="/Double Ring@1x-1.0s-200px-200px.gif"
        alt="Loading"
        className="h-32 w-32"
      />
      {text && (
        <p className="mt-4 text-gray-700 dark:text-gray-300">{text}</p>
      )}
    </div>
  );
};

export default LoadingIcon;
